// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.driveDetails {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.driveDetails .inner-div-details h2 {
  font-size: 18px;
}
.driveDetails .userListTitleContainer {
  margin-bottom: 20px;
}
.driveDetails .keyTask {
  margin: 16px 0;
  padding: 5px 10px;
  border-radius: 20px;
  color: #5A5A5A;
  background-color: #CFECFF;
  display: table;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .driveDetails .userListTitleContainer {
    margin-bottom: 0;
  }
  .driveDetails .keyTask {
    border-radius: 0.5rem;
  }
  .driveDetails .keyTask:first-of-type {
    margin-top: 0;
  }
  .driveDetails .keyTask:last-of-type {
    margin-bottom: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/driveDetails.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;AACD;AAEE;EACC,eAAA;AAAH;AAIC;EACC,mBAAA;AAFF;AAMC;EACC,cAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;AAJF;;AAQA;EAEE;IACC,gBAAA;EAND;EAQA;IACC,qBAAA;EAND;EAQA;IACC,aAAA;EAND;EAQA;IACC,gBAAA;EAND;AACF","sourcesContent":[".driveDetails {\n\tpadding: 20px;\n\tbox-sizing: border-box;\n\tdisplay: flex;\n\tflex-direction: column;\n\n\t.inner-div-details {\n\t\th2 {\n\t\t\tfont-size: 18px;\n\t\t}\t\n\t}\n\n\t.userListTitleContainer {\n\t\tmargin-bottom: 20px;\n\t}\n\n\t\n\t.keyTask {\n\t\tmargin: 16px 0;\n\t\tpadding: 5px 10px;\n\t\tborder-radius: 20px;\n\t\tcolor: #5A5A5A;\n\t\tbackground-color: #CFECFF;\n\t\tdisplay: table;\n\t\tfont-size: 14px;\n\t}\n}\n\n@media screen and (max-width: 768px) {\n\t.driveDetails {\n\t\t.userListTitleContainer {\n\t\t\tmargin-bottom: 0;\n\t\t}\n\t\t.keyTask {\n\t\t\tborder-radius: 0.5rem;\n\t\t}\n\t\t.keyTask:first-of-type {\n\t\t\tmargin-top: 0;\n\t\t}\n\t\t.keyTask:last-of-type {\n\t\t\tmargin-bottom: 0;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
