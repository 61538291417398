// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-password {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  position: relative;
  flex: 1 1;
}
.change-password .change-password-actions {
  position: absolute;
  bottom: 0;
  right: 0;
}
.change-password .change-password-actions > div {
  color: #fff;
  width: 200px;
  padding: 5px 0;
  text-align: center;
  border-radius: 20px;
  background-color: #479BD2;
  font-weight: 700;
  cursor: pointer;
}
.change-password .change-password-actions > div:last-child {
  margin-right: 25px;
}
.change-password .input-group {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  position: relative;
  width: 250px;
  border-bottom: 1px solid #818181;
}
.change-password .input-group input {
  width: 250px;
  outline: none;
  border: none;
  background: transparent;
  font-size: 14px;
  font-family: inherit;
  color: #a4a4a4;
  padding: 5px 0;
}

@media screen and (max-width: 768px) {
  .change-password .input-group {
    width: 100%;
  }
  .change-password .change-password-actions {
    display: flex;
    justify-content: center;
    position: static;
    position: initial;
    bottom: auto;
    bottom: initial;
    right: auto;
    right: initial;
    margin-top: auto;
  }
  .change-password .change-password-actions > div:last-child {
    margin-right: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/changePassword.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;EACA,SAAA;AACD;AACC;EACC,kBAAA;EACA,SAAA;EACA,QAAA;AACF;AACE;EACC,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;AACH;AACG;EACC,kBAAA;AACJ;AAIC;EACC,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,gCAAA;AAFF;AAIE;EACC,YAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,eAAA;EACA,oBAAA;EACA,cAAA;EACA,cAAA;AAFH;;AAOA;EAEE;IACC,WAAA;EALD;EAQA;IACC,aAAA;IACA,uBAAA;IACA,gBAAA;IAAA,iBAAA;IACA,YAAA;IAAA,eAAA;IACA,WAAA;IAAA,cAAA;IACA,gBAAA;EAND;EAQC;IACC,eAAA;EANF;AACF","sourcesContent":[".change-password {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmargin-top: 50px;\n\tposition: relative;\n\tflex: 1;\n\n\t.change-password-actions {\n\t\tposition: absolute;\n\t\tbottom: 0;\n\t\tright: 0;\n\n\t\t>div {\n\t\t\tcolor: #fff;\n\t\t\twidth: 200px;\n\t\t\tpadding: 5px 0;\n\t\t\ttext-align: center;\n\t\t\tborder-radius: 20px;\n\t\t\tbackground-color: #479BD2;\n\t\t\tfont-weight: 700;\n\t\t\tcursor: pointer;\n\n\t\t\t&:last-child {\n\t\t\t\tmargin-right: 25px;\n\t\t\t}\n\t\t}\n\t}\n\n\t.input-group {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tmargin-bottom: 60px;\n\t\tposition: relative;\n\t\twidth: 250px;\n\t\tborder-bottom: 1px solid #818181;\n\n\t\tinput {\n\t\t\twidth: 250px;\n\t\t\toutline: none;\n\t\t\tborder: none;\n\t\t\tbackground: transparent;\n\t\t\tfont-size: 14px;\n\t\t\tfont-family: inherit;\n\t\t\tcolor: #a4a4a4;\n\t\t\tpadding: 5px 0;\n\t\t}\n\t}\n}\n\n@media screen and (max-width: 768px) {\n\t.change-password {\n\t\t.input-group {\n\t\t\twidth: 100%;\n\t\t}\n\n\t\t.change-password-actions {\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: center;\n\t\t\tposition: unset;\n\t\t\tbottom: unset;\n\t\t\tright: unset;\n\t\t\tmargin-top: auto;\n\n\t\t\t>div:last-child {\n\t\t\t\tmargin-right: 0;\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
