// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trial-users-list {
  width: 100%;
}
.trial-users-list .header {
  display: flex;
}
.trial-users-list .header > div {
  padding: 5px 15px;
  border: 1px solid #ccc;
  border-right: none;
  font-weight: bold;
  width: 10%;
}
.trial-users-list .header > div:last-child {
  border-right: 1px solid #ccc;
}
.trial-users-list .header > div:first-child {
  width: 5%;
}
.trial-users-list .header > div:nth-child(2) {
  width: 15%;
}
.trial-users-list .header > div:nth-child(8) {
  width: 12%;
}
.trial-users-list .header > div:nth-child(9) {
  width: 8%;
}
.trial-users-list .rows > div {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-right: none;
  word-break: break-all;
  width: 10%;
}
.trial-users-list .rows > div:last-child {
  border-right: 1px solid #ccc;
}
.trial-users-list .rows > div:first-child {
  width: 5%;
}
.trial-users-list .rows > div:nth-child(2) {
  width: 15%;
}
.trial-users-list .rows > div:nth-child(8) {
  width: 12%;
}
.trial-users-list .rows > div:nth-child(9) {
  width: 8%;
}`, "",{"version":3,"sources":["webpack://./src/styles/trialUsers.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;AACD;AACC;EACC,aAAA;AACF;AACE;EACC,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,iBAAA;EACA,UAAA;AACH;AACG;EACC,4BAAA;AACJ;AAEG;EACC,SAAA;AAAJ;AAGG;EACC,UAAA;AADJ;AAIG;EACC,UAAA;AAFJ;AAKG;EACC,SAAA;AAHJ;AASE;EACC,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,qBAAA;EACA,UAAA;AAPH;AASG;EACC,4BAAA;AAPJ;AAUG;EACC,SAAA;AARJ;AAWG;EACC,UAAA;AATJ;AAYG;EACC,UAAA;AAVJ;AAaG;EACC,SAAA;AAXJ","sourcesContent":[".trial-users-list {\n\twidth: 100%;\n\n\t.header {\n\t\tdisplay: flex;\n\n\t\t> div {\n\t\t\tpadding: 5px 15px;\n\t\t\tborder: 1px solid #ccc;\n\t\t\tborder-right: none;\n\t\t\tfont-weight: bold;\n\t\t\twidth: 10%;\n\n\t\t\t&:last-child {\n\t\t\t\tborder-right: 1px solid #ccc;\n\t\t\t}\n\n\t\t\t&:first-child {\n\t\t\t\twidth: 5%;\n\t\t\t}\n\n\t\t\t&:nth-child(2) {\n\t\t\t\twidth: 15%;\n\t\t\t}\n\n\t\t\t&:nth-child(8) {\n\t\t\t\twidth: 12%;\n\t\t\t}\n\n\t\t\t&:nth-child(9) {\n\t\t\t\twidth: 8%;\n\t\t\t}\n\t\t}\n\t}\n\n\t.rows {\n\t\t> div {\n\t\t\tpadding: 5px 10px;\n\t\t\tborder: 1px solid #ccc;\n\t\t\tborder-right: none;\n\t\t\tword-break: break-all;\n\t\t\twidth: 10%;\n\n\t\t\t&:last-child {\n\t\t\t\tborder-right: 1px solid #ccc;\n\t\t\t}\n\n\t\t\t&:first-child {\n\t\t\t\twidth: 5%;\n\t\t\t}\n\n\t\t\t&:nth-child(2) {\n\t\t\t\twidth: 15%;\n\t\t\t}\n\n\t\t\t&:nth-child(8) {\n\t\t\t\twidth: 12%;\n\t\t\t}\n\n\t\t\t&:nth-child(9) {\n\t\t\t\twidth: 8%;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
