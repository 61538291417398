// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-container {
  box-sizing: border-box;
}
.avatar-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.avatar-text {
  border-radius: 50%;
  background: #C4C4C4;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  padding: 0.5rem;
  min-width: 1.25em;
}`, "",{"version":3,"sources":["webpack://./src/styles/Avatar.scss"],"names":[],"mappings":"AACI;EACI,sBAAA;AAAR;AAGI;EACI,YAAA;EACA,aAAA;EACA,kBAAA;AADR;AAII;EACI,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;EACA,iBAAA;AAFR","sourcesContent":[".avatar {\n    &-container {\n        box-sizing: border-box;\n    }\n\n    &-image {\n        width: 100px;\n        height: 100px;\n        border-radius: 50%;\n    }\n\n    &-text {\n        border-radius: 50%;\n        background: #C4C4C4;\n        text-transform: uppercase;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        font-size: 20px;\n        font-weight: 700;\n        color: #fff;\n        padding: 0.5rem;\n        min-width: 1.25em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
