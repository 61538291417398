// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManageFeatures_container__-gSCk {
    flex: 1 1;
    padding: 20px 20px 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;
}

.ManageFeatures_headerContainer__87lmY {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ManageFeatures_heading__XLG9n {
    font-size: 20px;
    font-weight : 600;
}

.ManageFeatures_subHeading__Wmh1N {
    font-size: 16px;
    font-weight: 600;
    color: #8b8b8b;
}

.ManageFeatures_saveButton__VPLd\\+ {
    padding: 5px 15px;
    background: #479bd2;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: #fff;
}

.ManageFeatures_tableContainer__ZJgkr {
    background: #fff;
    border-radius: 20px;
    margin-top: 20px;
    padding: 20px;
    flex: 1 1;
    overflow-y: auto;
}

.ManageFeatures_tableHeaderContainer__y98Vg {
    display: flex;
    border-bottom: 1px solid #c4c4c4;
}

.ManageFeatures_tableHeader__LraMp,
.ManageFeatures_tableContent__16B0b {
    flex: 1 1;
    padding: 10px 20px;
}

.ManageFeatures_tableContentContainer__3CA4G {
    display: flex;
    border-bottom: 1px solid #c4c4c4;
}

.ManageFeatures_flex2__-JfQJ {
    flex: 2 1;
}

.ManageFeatures_flex4__g-grb {
    flex: 4 1;
}
`, "",{"version":3,"sources":["webpack://./src/containers/ManageFeatures/ManageFeatures.module.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,oBAAoB;IACpB,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,qBAAqB;IACrB,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,SAAO;IACP,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,gCAAgC;AACpC;;AAEA;;IAEI,SAAO;IACP,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,SAAO;AACX","sourcesContent":[".container {\n    flex: 1;\n    padding: 20px 20px 0;\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n    box-sizing: border-box;\n}\n\n.headerContainer {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.heading {\n    font-size: 20px;\n    font-weight : 600;\n}\n\n.subHeading {\n    font-size: 16px;\n    font-weight: 600;\n    color: #8b8b8b;\n}\n\n.saveButton {\n    padding: 5px 15px;\n    background: #479bd2;\n    border-radius: 5px;\n    cursor: pointer;\n    display: inline-block;\n    text-decoration: none;\n    color: #fff;\n}\n\n.tableContainer {\n    background: #fff;\n    border-radius: 20px;\n    margin-top: 20px;\n    padding: 20px;\n    flex: 1;\n    overflow-y: auto;\n}\n\n.tableHeaderContainer {\n    display: flex;\n    border-bottom: 1px solid #c4c4c4;\n}\n\n.tableHeader,\n.tableContent {\n    flex: 1;\n    padding: 10px 20px;\n}\n\n.tableContentContainer {\n    display: flex;\n    border-bottom: 1px solid #c4c4c4;\n}\n\n.flex2 {\n    flex: 2;\n}\n\n.flex4 {\n    flex: 4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ManageFeatures_container__-gSCk`,
	"headerContainer": `ManageFeatures_headerContainer__87lmY`,
	"heading": `ManageFeatures_heading__XLG9n`,
	"subHeading": `ManageFeatures_subHeading__Wmh1N`,
	"saveButton": `ManageFeatures_saveButton__VPLd+`,
	"tableContainer": `ManageFeatures_tableContainer__ZJgkr`,
	"tableHeaderContainer": `ManageFeatures_tableHeaderContainer__y98Vg`,
	"tableHeader": `ManageFeatures_tableHeader__LraMp`,
	"tableContent": `ManageFeatures_tableContent__16B0b`,
	"tableContentContainer": `ManageFeatures_tableContentContainer__3CA4G`,
	"flex2": `ManageFeatures_flex2__-JfQJ`,
	"flex4": `ManageFeatures_flex4__g-grb`
};
export default ___CSS_LOADER_EXPORT___;
