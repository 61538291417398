// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-case {
  list-style: none;
  display: flex;
  margin-bottom: 10px;
}

.finalizedContainer {
  overflow-x: scroll;
}

.toggle-case li {
  margin-right: 10px;
  cursor: pointer;
}

.toggle-case .active {
  color: orange;
  -webkit-text-decoration: underline orange;
          text-decoration: underline orange;
}

.MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer {
  text-align: center;
}

.container-team {
  margin: 25px;
}

.reviewIconBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* @media screen and (min-width: 1366px){
    .userList{
      width: 1000px;
    }
  }

  @media screen and (min-width: 1280px){
    .userList{
      width: 1000px;
    }
  } */
@media screen and (max-width: 768px) {
  .reviewIconBox {
    display: none;
  }
  .finalizedContainer {
    overflow-x: hidden;
  }
  .finalizedContainer .userList {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .bottomDrawer__img-box {
    display: flex;
    justify-content: flex-end;
  }
  .bottomDrawer__checkbox-grp {
    margin-bottom: 1rem;
  }
  .bottomDrawer__checkbox-grp input[type=checkbox] {
    width: 14px;
    height: 14px;
  }
  .bottomDrawer__checkbox-grp label {
    color: #334d6e;
    font-size: 18px;
    margin-left: 0.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/reviewedApplicants.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;EACA,aAAA;EACA,mBAAA;AACD;;AAEA;EACC,kBAAA;AACD;;AAEA;EACC,kBAAA;EACA,eAAA;AACD;;AAEA;EACC,aAAA;EACA,yCAAA;UAAA,iCAAA;AACD;;AAEA;EACC,kBAAA;AACD;;AAEA;EACC,YAAA;AACD;;AAEA;EACC,aAAA;EACA,uBAAA;EACA,mBAAA;AACD;;AAEA;;;;;;;;;;KAAA;AAYA;EACC;IACC,aAAA;EAAA;EAED;IACC,kBAAA;EAAA;EAED;IACC,YAAA;IACA,kBAAA;IACA,kBAAA;EAAA;EAED;IACC,aAAA;IACA,yBAAA;EAAA;EAGD;IACC,mBAAA;EADA;EAID;IACC,WAAA;IACA,YAAA;EAFA;EAKD;IACC,cAAA;IACA,eAAA;IACA,mBAAA;EAHA;AACF","sourcesContent":[".toggle-case {\n\tlist-style: none;\n\tdisplay: flex;\n\tmargin-bottom: 10px;\n}\n\n.finalizedContainer {\n\toverflow-x: scroll;\n}\n\n.toggle-case li {\n\tmargin-right: 10px;\n\tcursor: pointer;\n}\n\n.toggle-case .active {\n\tcolor: orange;\n\ttext-decoration: underline orange;\n}\n\n.MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer {\n\ttext-align: center;\n}\n\n.container-team {\n\tmargin: 25px;\n}\n\n.reviewIconBox {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n/* @media screen and (min-width: 1366px){\n    .userList{\n      width: 1000px;\n    }\n  }\n  \n  @media screen and (min-width: 1280px){\n    .userList{\n      width: 1000px;\n    }\n  } */\n\n@media screen and (max-width: 768px) {\n\t.reviewIconBox {\n\t\tdisplay: none;\n\t}\n\t.finalizedContainer {\n\t\toverflow-x: hidden;\n\t}\n\t.finalizedContainer .userList {\n\t\theight: 100%;\n\t\toverflow-y: scroll;\n\t\toverflow-x: hidden;\n\t}\n\t.bottomDrawer__img-box {\n\t\tdisplay: flex;\n\t\tjustify-content: flex-end;\n\t}\n\n\t.bottomDrawer__checkbox-grp {\n\t\tmargin-bottom: 1rem;\n\t}\n\n\t.bottomDrawer__checkbox-grp input[type=\"checkbox\"] {\n\t\twidth: 14px;\n\t\theight: 14px;\n\t}\n\n\t.bottomDrawer__checkbox-grp label {\n\t\tcolor: #334d6e;\n\t\tfont-size: 18px;\n\t\tmargin-left: 0.5rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
