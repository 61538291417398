// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.integration-page {
  flex: 1 1;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.integration-wrapper {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #D3D3D3;
  border-radius: 0.5rem;
  margin-bottom: 20px;
}
.integration-wrapper:last-child {
  margin-bottom: 0;
}
.integration-wrapper-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.integration-wrapper-row-left {
  display: flex;
  align-items: center;
}
.integration-wrapper-row-left-img {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 1rem;
  border-radius: 4px;
}
.integration-wrapper-row-left-name {
  font-size: 1.1rem;
  font-weight: bold;
}
.integration-wrapper-desc {
  font-size: 1rem;
  margin-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/Integrations.scss"],"names":[],"mappings":"AACI;EACI,SAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;AAAR;AAGI;EACI,sBAAA;EACA,aAAA;EACA,yBAAA;EACA,qBAAA;EACA,mBAAA;AADR;AAGQ;EACI,gBAAA;AADZ;AAIQ;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AAFZ;AAIY;EACI,aAAA;EACA,mBAAA;AAFhB;AAIgB;EACI,qBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;AAFpB;AAKgB;EACI,iBAAA;EACA,iBAAA;AAHpB;AAQQ;EACI,eAAA;EACA,gBAAA;AANZ","sourcesContent":[".integration {\n    &-page {\n        flex: 1;\n        padding: 20px;\n        box-sizing: border-box;\n        min-height: 100vh;\n        display: flex;\n        flex-direction: column;\n    }\n\n    &-wrapper {\n        background-color: #fff;\n        padding: 20px;\n        border: 1px solid #D3D3D3;\n        border-radius: 0.5rem;\n        margin-bottom: 20px;\n\n        &:last-child {\n            margin-bottom: 0;\n        }\n\n        &-row {\n            display: flex;\n            align-items: center;\n            justify-content: space-between;\n\n            &-left {\n                display: flex;\n                align-items: center;\n\n                &-img {\n                    display: inline-block;\n                    width: 40px;\n                    height: 40px;\n                    margin-right: 1rem;\n                    border-radius: 4px;\n                }\n\n                &-name {\n                    font-size: 1.1rem;\n                    font-weight: bold;\n                }\n            }\n        }\n\n        &-desc {\n            font-size: 1rem;\n            margin-top: 1rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
