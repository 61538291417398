// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-checkbox {
  position: relative;
}

.custom-checkbox label {
  padding-left: 30px;
  display: block;
  line-height: 24px;
}

.custom-checkbox label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox label .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  border: 2px solid rgb(184, 184, 184);
  cursor: pointer;
  border-radius: 3px;
}

.custom-checkbox label input:checked ~ .checkmark {
  background-color: #479bd2;
  border-color: #479bd2;
}

.custom-checkbox label input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 0;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-checkbox label .green-input:checked ~ .checkmark {
  background-color: #043840;
  border-color: #043840;
}

.custom-checkbox label .green-checkmark {
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/styles/Checkbox.scss"],"names":[],"mappings":"AAAA;EACG,kBAAA;AACH;;AAEA;EACG,kBAAA;EACA,cAAA;EACA,iBAAA;AACH;;AAEA;EACG,kBAAA;EACA,UAAA;EACA,eAAA;EACA,SAAA;EACA,QAAA;AACH;;AAEA;EACG,kBAAA;EACA,QAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,oCAAA;EACA,eAAA;EACA,kBAAA;AACH;;AAEA;EACG,yBAAA;EACA,qBAAA;AACH;;AAEA;EACG,cAAA;AACH;;AAEA;EACG,WAAA;EACA,kBAAA;EACA,aAAA;EACA,SAAA;EACA,MAAA;EACA,UAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EAEA,wBAAA;AACH;;AAGG;EACG,yBAAA;EACA,qBAAA;AAAN;;AAIA;EACG,kBAAA;AADH","sourcesContent":[".custom-checkbox {\n   position: relative;\n}\n\n.custom-checkbox label {\n   padding-left: 30px;\n   display: block;\n   line-height: 24px;\n}\n\n.custom-checkbox label input {\n   position: absolute;\n   opacity: 0;\n   cursor: pointer;\n   height: 0;\n   width: 0;\n}\n\n.custom-checkbox label .checkmark {\n   position: absolute;\n   top: 2px;\n   left: 0;\n   height: 16px;\n   width: 16px;\n   border: 2px solid rgb(184, 184, 184);\n   cursor: pointer;\n   border-radius: 3px;\n}\n\n.custom-checkbox label input:checked~.checkmark {\n   background-color: #479bd2;\n   border-color: #479bd2;\n}\n\n.custom-checkbox label input:checked~.checkmark:after {\n   display: block;\n}\n\n.custom-checkbox label .checkmark:after {\n   content: \"\";\n   position: absolute;\n   display: none;\n   left: 5px;\n   top: 0;\n   width: 4px;\n   height: 10px;\n   border: solid white;\n   border-width: 0 2px 2px 0;\n   -webkit-transform: rotate(45deg);\n   transform: rotate(45deg);\n}\n\n.custom-checkbox label .green-input {\n   &:checked~.checkmark {\n      background-color: #043840;\n      border-color: #043840;\n   }\n}\n\n.custom-checkbox label .green-checkmark {\n   border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
