import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { DataGrid } from "@mui/x-data-grid";
import { Stack, Switch } from "@mui/material";

import Button from "../components/common/Button";
import Modal from "../components/common/CustomModal";
import NoRowsOverlay from "../components/common/NoRowsDataGridOverlay";

import { getCompaniesList, getWorkflowList } from "../selectors/app";
import {
    fetchWorkflows as fetchWorkflowsRequest,
    fetchCompanies as fetchCompaniesRequest,
    updateWorkflowAdmin as updateWorkflowAdminRequest,
} from "../actions/app";

const initialState = [
    {
        key: "customTrialBotId",
        value: "",
        inputType: "text",
        label: "Change Custom Trial Bot ID",
    },
    {
        key: "assessmentAcceptText",
        value: "",
        inputType: "textarea",
        label: "Change First Bubble Text",
    },
    {
        key: "nextSectionMessage",
        value: "",
        inputType: "textarea",
        label: "Change Next Section Message",
    },
    {
        key: "nextSectionBtnText",
        value: "",
        inputType: "textarea",
        label: "Change Next Section Button Text",
    },
    {
        key: "videoProctoring",
        value: false,
        inputType: "switch",
        label: "Change Video Proctoring Status",
    },
    {
        key: "videoQuestion",
        value: false,
        inputType: "switch",
        label: "Change Video Question Status",
    },
    {
        key: "sectionCount",
        value: 1,
        inputType: "number",
        label: "Change Section Count",
    },
    {
        key: "assessmentTime",
        value: 30,
        inputType: "number",
        label: "Change Assessment TIme",
    },
    {
        key: "features.assessmentTimer",
        value: true,
        inputType: "switch",
        label: "Change Assessment Timer Status",
    },
    {
        key: "features.videoStartTimer",
        value: false,
        inputType: "switch",
        label: "Change Video Start Timer Status",
    },
    {
        key: "features.autoVideoUpload",
        value: false,
        inputType: "switch",
        label: "Change Auto-video Upload Status",
    },
];

const DEFAULT_ACCEPT_TEXT =
    "I will be assessing your application for this role. This assessment will take about 30 minutes in total to complete.::::Should you encounter any technical difficulties, please close this tab and visit the link that was shared with you - you will not lose your place in the test. Should you continue to experience difficulties, please email support@hirequotient.com";

const ManageWorkflows = (props) => {
    const {
        updateWorkflowAdmin,
        allCompanies,
        workflowList,
        fetchCompanies,
        fetchWorkflows,
    } = props;

    const [editModal, setEditModal] = useState(false);
    const [selectedWorkflow, setSelectedWorkflow] = useState();
    const [selectedCompany, setSelectedCompany] = useState();
    const [workflowParams, setWorkflowParams] = useState(initialState);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 15,
    });

    useEffect(() => {
        fetchCompanies();
    }, []);

    const updateWorkflowParams = (key, value) => {
        setWorkflowParams((prevState) =>
            prevState.map((param) =>
                param.key === key ? { ...param, value } : param
            )
        );
    };

    const onOpenEditModal = (e, val) => {
        e.stopPropagation();
        setSelectedWorkflow(val);
        setWorkflowParams((prevState) =>
            prevState.map((param) => {
                const keys = param.key.split(".");
                let value = val;
                for (let key of keys) {
                    if (value[key] === undefined) {
                        value =
                            param.key === "assessmentAcceptText"
                                ? DEFAULT_ACCEPT_TEXT
                                : param.value;
                        break;
                    }
                    value = value[key];
                }
                return {
                    ...param,
                    value,
                };
            })
        );
        setEditModal(true);
    };

    const onCloseEditModal = () => {
        setEditModal(false);
    };

    const onSubmitAcceptText = () => {
        const data = {
            ...workflowParams.reduce((acc, param) => {
                const keys = param.key.split(".");
                if (keys.length > 1) {
                    // Handle nested keys
                    if (!acc[keys[0]]) {
                        acc[keys[0]] = {};
                    }
                    acc[keys[0]][keys[1]] = param.value;
                } else {
                    // Handle regular keys
                    acc[param.key] = param.value;
                }
                return acc;
            }, {}),
            companyId: selectedCompany,
            workflowId: selectedWorkflow._id,
        };

        updateWorkflowAdmin(data);
        onCloseEditModal();
    };

    const onChangeCompany = (e) => {
        setSelectedCompany(e.target.value);
        fetchWorkflows({ companyId: e.target.value });
    };

    const columns = [
        {
            field: "name",
            headerName: "Name",
            align: "center",
            headerAlign: "center",
            flex: 2,
            renderCell: (params) => {
                return <>{params.row.name}</>;
            },
        },
        {
            field: "createdDate",
            headerName: "Date Created",
            flex: 1,
            renderCell: (params) => {
                const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                };
                const date = new Date(
                    params.row.createdDate
                ).toLocaleDateString("en-US", options);
                return <>{date}</>;
            },
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Button
                            className="btn-datagrid"
                            size="small"
                            variant="contained"
                            onClick={(e) => onOpenEditModal(e, params.row)}
                        >
                            Edit
                        </Button>
                    </>
                );
            },
        },
    ];

    const renderInput = (param) => {
        switch (param.inputType) {
            case "textarea":
                return (
                    <div key={param.key}>
                        <div>{param.label}</div>
                        <div className="modal__inputbox">
                            <textarea
                                rows={2}
                                placeholder={param.label}
                                value={param.value}
                                onChange={(e) =>
                                    updateWorkflowParams(
                                        param.key,
                                        e.target.value
                                    )
                                }
                                style={{ width: "90%" }}
                            />
                        </div>
                    </div>
                );
            case "switch":
                return (
                    <Stack
                        key={param.key}
                        width="100%"
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <div>{param.label}</div>
                        <Switch
                            checked={param.value}
                            onChange={(e) =>
                                updateWorkflowParams(
                                    param.key,
                                    e.target.checked
                                )
                            }
                        />
                    </Stack>
                );
            case "number":
            case "text":
                return (
                    <div key={param.key}>
                        <div>{param.label}</div>
                        <div className="modal__inputbox">
                            <input
                                type={param.inputType}
                                placeholder={param.label}
                                value={param.value}
                                onChange={(e) =>
                                    updateWorkflowParams(
                                        param.key,
                                        param.inputType === "number"
                                            ? Number(e.target.value)
                                            : e.target.value
                                    )
                                }
                                style={{ width: "90%" }}
                            />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div
            className="mainbar"
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                minHeight: "100vh",
            }}
        >
            <Modal
                open={editModal}
                onClose={onCloseEditModal}
                classNames={{ modal: "modal__container" }}
            >
                <div className="modal__heading">
                    {selectedWorkflow && selectedWorkflow.name}
                </div>
                {workflowParams.map(renderInput)}
                <div className="modal__btnbox">
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onSubmitAcceptText}
                    >
                        Submit
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onCloseEditModal}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>

            <div className="notificationbar"></div>
            <div className="actionbar" />
            <div
                style={{
                    padding: 40,
                    fontSize: 24,
                    fontWeight: "bold",
                    background: "#fff",
                }}
            >
                Workflows
            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: 25,
                    justifyContent: "space-between",
                }}
            >
                <select onChange={onChangeCompany}>
                    <option>Select Company</option>
                    {allCompanies.map((comp) => (
                        <option key={comp._id} value={comp._id}>
                            {comp.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="datagrid__container">
                <DataGrid
                    rows={workflowList}
                    getRowId={(row) => row._id}
                    disableRowSelectionOnClick
                    columns={columns}
                    slots={{
                        noRowsOverlay: NoRowsOverlay,
                    }}
                    sx={{ "--DataGrid-overlayHeight": "300px" }}
                    autoHeight
                    pageSizeOptions={[5, 10, 15]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    className="datagrid__scroll scroll-blue"
                />
            </div>
        </div>
    );
};

ManageWorkflows.propTypes = {
    workflowList: PropTypes.array,
    fetchWorkflows: PropTypes.func,
    allCompanies: PropTypes.array,
    fetchCompanies: PropTypes.func,
    updateWorkflowAdmin: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    workflowList: getWorkflowList(),
    allCompanies: getCompaniesList(),
});

const mapDispatchToProps = (dispatch) => ({
    fetchWorkflows: (data) => dispatch(fetchWorkflowsRequest(data)),
    updateWorkflowAdmin: (data) => dispatch(updateWorkflowAdminRequest(data)),
    fetchCompanies: () => dispatch(fetchCompaniesRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageWorkflows);
