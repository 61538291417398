// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerContainer {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 1rem 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidebarContainer, .optionsContainer {
    width: 20px;
    height: 20px;
}

.hqLogoContainer {
    width: 100%;
    max-width: 150px;
    height: auto;
    margin: auto;
}

.imgContain {
    object-fit: contain;
    width: 100%;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":"AAAA;IACI,wBAAgB;IAAhB,gBAAgB;IAChB,MAAM;IACN,WAAW;IACX,uBAAuB;IACvB,2CAA2C;IAC3C,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,YAAY;AAChB","sourcesContent":[".headerContainer {\n    position: sticky;\n    top: 0;\n    z-index: 10;\n    background-color: white;\n    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;\n    padding: 1rem 20px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.sidebarContainer, .optionsContainer {\n    width: 20px;\n    height: 20px;\n}\n\n.hqLogoContainer {\n    width: 100%;\n    max-width: 150px;\n    height: auto;\n    margin: auto;\n}\n\n.imgContain {\n    object-fit: contain;\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
